<template>
  <section class="l-content">
    <el-card class="l-condition" name="hTable">
      <el-form :inline="true" :model="condition">
        <el-form-item label="所属企业" v-if="userInfo.eGroupCode">
          <l-select v-model="condition.enterpriseCode" :data="userInfo.eGroupChildren" :prop="['enterpriseCode', 'enterpriseName']" @change="getData"></l-select>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            <l-select v-model="condition.dateType" :all="false" :data="$constant.advice_dateType"></l-select>
          </template>
          <l-datepicker type="daterange" :default="[condition.startDate, condition.endDate]" @change="dataRangeChange"></l-datepicker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getData()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>

    <el-card v-if="pageData">
<div style="width:600px">
        <h3 style="text-align: center">数据采集表</h3>
        <h5 style="text-align: left">单位名称：{{ pageData.deparmentName }}</h5>
    <table class="table-word" style="width:100%;font-size: 13px;">
                  <tr>
                    <th>序号</th>
                    <th>内容</th>
                    <th>数据</th>
                  </tr>
                  <tr v-for="(vl,nm ,index) in pageData.countByState" :key="index">
                    <td>{{index+1}}</td>
                    <td style="text-align: left">{{nm}}</td>
                    <td style="text-align: right;padding-right:18px;">{{vl}}</td>
                  </tr>
                  <tr>
                    <td colspan="3" style="text-align:left;">合理化建议类别（条）</td>
                  </tr>
                </table>
                <table class="table-word" style="width:100%;font-size: 13px;border-top:none;">
                        <tr  style="border-top:none;">
                          <th v-for="(item,index) in pageData.countByType" :key="index"   style="border-top:none;">{{item.key}}</th>
                        </tr>
                        <tr>
                          <td v-for="(item,index) in pageData.countByType" :key="index">{{item.count}}</td>
                        </tr>
                      </table>
              </div>
      </el-card>
    </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'AdviceList',
  data() {
    return {
      condition: {
        departId: '',
        dateType: 'submitTime',
        startDate: '',
        endDate: ''
      },
      pageData: null
    }
  },
  created() {
    this.condition.enterpriseCode = this.userInfo.enterpriseCode
    this.condition.eGroupCode = this.userInfo.eGroupCode
    this.getData()
  },
  methods: {
    dataRangeChange(d1, d2) {
      this.condition.startDate = d1
      this.condition.endDate = d2
    },
    async getData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/advice/department/gahter', this.condition)
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总价'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] += ' 元'
        } else {
          sums[index] = 'N/A'
        }
      })

      return sums
    }
  }
}
</script>
